import React, { ReactNode } from "react";
import { ButtonProps, Col, FormInstance, Modal as AntModal, notification, Row, Spin, Tooltip } from "antd";
import { Form, ModalProps } from "antd/es";
import { callAll, formatDate, setTriggerEventName, toRelativeUrl } from "../utils/utils";
import useNormalizeData from "../utils/normalizedRecord";
import queryClient, { useMutation } from "components/utils/queryClient";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import RoleGuard from "components/standalone/role-guard";
import dayjs from "dayjs";
import { AccessControlTypes, JobFeedback, RoleGuardProps, Script } from "types";
import { useNavigate } from "react-router";
interface ModalContextProps {
  isOpen: boolean;
  isSaving: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormInstance<any>;
  onOk: (key: any, action: any, resource: any, onSuccess?: any) => Promise<any>;
}

const ModalContext = React.createContext<ModalContextProps>(null);

function setTypeValue(type: string, values) {
  if (type === "simple") {
    return { cron: values.simple };
  }
  if (type === "cron") {
    return { cron: values.cron };
  }
  if (type === "continuous") {
    const hours = Math.floor(values.delay / 3600);
    const minutes = Math.floor(values.delay / 60);
    const seconds = values.delay % 60;

    return { delay: `0.${hours}:${minutes}:${seconds}.0000000` };
  }
  if (type === "oneTime") {
    let myUtc = formatDate(values.datetime);
    return { oneTime: `/Date(${dayjs(myUtc).valueOf()})/` };
  }
}

function Modal({ children, ...props }) {
  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSaving, setIsSaving] = React.useState(false);
  const normalizedRecord = useNormalizeData();
  const navigate = useNavigate();

  const { mutateAsync } = useMutation<any>();

  const onOk = React.useCallback(
    async (key: any, action: any, resource: any, onSuccess: any) => {
      let values = await form.validateFields();
      if (key === "/tag" && action === "create") {
        values["color"] = values?.color?.hex;
      }

      if (key === "/trigger" && action === "update") {
        var eventName = setTriggerEventName(values?.eventType);
        if (eventName?.startsWith("Job")) {
          values["dashboard"] = null;
        }
        if (eventName?.startsWith("Dashboard")) {
          values["script"] = null;
        }
        if (eventName?.startsWith("Server")) {
          values["script"] = null;
          values["dashboard"] = null;
        }
      }

      if (key === "/apptoken/grant") {
        values["expiration"] = dayjs()
          .add(parseInt(values?.expiration), "days")
          .format("lll");
        resource.creation = dayjs().format("lll");
        values["identity"] = {
          name: values?.identity,
        };
      }

      if (key === "/schedule" || (resource?.resourceInfo?.schemaName === "schedule" && action === "update")) {
        let newSchedule = {
          ...setTypeValue(values.type, values),
          continuous: values.type === "continuous" ? true : false,
          timeZoneString: values.timeZoneString || Intl.DateTimeFormat().resolvedOptions().timeZone,
          parameters: values.parameters?.map((parameter) => {
            return {
              name: parameter.name,
              objectValue: JSON.stringify(parameter.displayValue),
              type: parameter.type,
            }
          }),
          credential: values?.credential,
          environment:
            values.environment === "Default" ? null : values?.environment,
          script: values.script,
          name: values.name,
          paused: values.paused,
          timeout: values.timeout,
          randomDelay: values.randomDelay,
          id: resource.id,
          computer: values.computer
        };

        resource = newSchedule;
        values = {};
      }

      if (key === "/hotkey" || (resource?.resourceInfo?.schemaName === "hotkey" && action === "update")) {
        let newHotkey = {
          parameters: queryClient
            .getQueryData<Script[]>("/script")
            ?.find((script) => script.fullPath === values.script)
            ?.scriptParameters.map((parameter) => ({
              name: parameter.name,
              value: parameter.type === 'System.String[]' ? JSON.stringify(values[parameter.name]) : values[parameter.name],
              type: parameter.type,
            })),
          credential: values?.credential,
          environment:
            values.environment === "Default" ? null : values?.environment,
          script: values.script,
          keys: values.keys,
          modifierKeys: values.modifierKeys,
          id: resource.id,
        };

        resource = newHotkey;
        values = {};
      }

      if (key === "/variable") {
        values["secret"] = values?.variableType === "secret";
        values["value"] = values?.type === "System.Collections.ArrayList" ? JSON.stringify(values?.value) : values?.value;
      }

      if (
        resource?.resourceInfo?.schemaName === "jobfeedback" &&
        action === "update"
      ) {
        let newFeedback: JobFeedback = {
          ...resource,
          complete: true,
          data: values?.data,
        };
        resource = newFeedback;
        values = {};
      }

      if (resource?.resourceInfo?.schemaName === "script") {
        values["environment"] =
          values.environment === "Default" ? null : values.environment;
      }

      if (key === "/accessControl" || resource?.resourceInfo?.schemaName === "accessControl") {
        let accessType = 0;
        values.type.forEach(x => accessType |= x);
        values["type"] = accessType;
        values["tag"] = values.tag?.value;
        values["resourceInfo"] = {
          parent: "/accessControl"
        }
      }


      if ((key === "/ratelimit" && action === "create") || resource?.resourceInfo?.schemaName === "ratelimit") {
        const { method, ...rest } = values;
        values = rest;
        values["endpoint"] = `${method}:${rest?.endpoint}`;
        values["action"] = action;
      }

      if (key === "/publishedfolder" && action === "create") {
        values["defaultDocument"] = [values?.defaultDocument];
      }

      if (key === "/dashboard") {
        if (action === "create") {
          let newDashboard = {
            ...values,
            disableAutoStart: !values.autoStart,
            environment:
              values.environment === "Default" ? null : values.environment,
            dashboardFramework: {
              id: values.dashboardFramework,
            },
          };
          resource = newDashboard;
          values = {};
        }
      }

      if (key === "/endpoint") {
        if (action === "create") {
          let newEndpoint = {
            ...values,
            method: Array.isArray(values.method) ? values.method : [values.method],
            errorAction: values.errorAction || 1
          };
          resource = newEndpoint;
          values = {};
        }
      }

      setIsSaving(true);
      await mutateAsync(
        { key, action, ...normalizedRecord({ ...resource, ...values }) },
        {
          onError: (error) => {
            setIsOpen(false);
            setIsSaving(false);
            notification.error({
              message: "Error",
              description: error?.response?.data,
              icon: <ExclamationCircleOutlined style={{ color: 'red' }} />,
            })
          },
          onSuccess: (data, variables) => {
            setIsSaving(false);
            setIsOpen(false);
            if (variables?.key === "/license") {
              queryClient.refetchQueries("/license/feature");
            }

            if (variables?.resourceInfo?.schemaName === "endpointDocumentation") {
              queryClient.refetchQueries("/endpoint/doc");
            }

            if (new RegExp('/dashboard/\\d/page').test(variables?.key)) {
              queryClient.refetchQueries(variables?.key);
            }

            if (variables?.key === '/template/export') {
              window.location.href = toRelativeUrl(`/api/v1/template/export/${data}`)
            }

            if (values?.gotoJob) {
              navigate(toRelativeUrl(`/admin/automation/jobs/${data}`));
            }
            if (values?.gotoScript) {
              navigate(toRelativeUrl(`/admin/automation/scripts/${data.fullPath}`));
            }
            form.resetFields();

            if (onSuccess)
              onSuccess(data);
          },
        }
      );
    },
    [form, mutateAsync, navigate, normalizedRecord]
  );

  const value = React.useMemo(() => ({ isOpen, setIsOpen, form, onOk, isSaving }), [
    isOpen,
    setIsOpen,
    form,
    onOk,
    isSaving,
  ]);

  return (
    <ModalContext.Provider value={value}><div onMouseLeave={e => e.stopPropagation()}>{children}</div></ModalContext.Provider>
  );
}

type ModalOpenButtonProps = {
  children: any;
  requiredRoles: Array<string>;
  requiredAccessControls: AccessControlTypes;
  allowedWithOneWayGitSync: boolean;
} & Omit<ButtonProps, "resource"> &
  Omit<RoleGuardProps, "children">;

function ModalOpenButton(props: ModalOpenButtonProps) {
  const { setIsOpen } = React.useContext(ModalContext);

  const child = React.Children.only(props.children);

  return (
    <RoleGuard
      requiredRoles={props.requiredRoles}
      requiredAccessControls={props.requiredAccessControls}
      accessControls={props.accessControls}
      allowedWithOneWayGitSync={props.allowedWithOneWayGitSync}
      noDemo={props.noDemo}
    >
      {child?.props?.action === "edit" ? (
        <Tooltip title="Edit Properties">
          {React.cloneElement(child, {
            type: child?.props?.type || "dashed",
            icon: child?.props?.icon || <PlusOutlined />,
            onClick: callAll(() => setIsOpen(true), child?.props?.onClick),
          })}
        </Tooltip>
      ) : (
        React.cloneElement(child, {
          type: child?.props?.type || "dashed",
          icon: child?.props?.icon || <PlusOutlined />,
          onClick: callAll(() => setIsOpen(true), child?.props?.onClick),
        })
      )}
    </RoleGuard>
  );
}

function ModalContentBase({
  ...props
}: ModalProps & {
  children?: ReactNode;
}) {
  const { isOpen, isSaving } = React.useContext(ModalContext);
  return (
    <AntModal
      {...props}
      visible={isOpen}
      confirmLoading={isSaving}
      destroyOnClose
      maskClosable
      closable
      width={800}
    />
  );
}

function ModalContent({ title, children, onClickOk, ...props }) {
  const { setIsOpen, form, isSaving } = React.useContext(ModalContext);

  return (
    <ModalContentBase
      {...props}
      title={title}
      centered={true}
      forceRender={false}
      onOk={callAll(onClickOk)}
      okButtonProps={{ htmlType: "submit", form: props.formName }}
      onCancel={callAll(
        () => setIsOpen(false),
        form.resetFields(),
        props?.onCancel
      )}
    >
      {isSaving ? <Row align="middle"><Col><Spin size="large" tip="Saving..." /></Col></Row> : children}
    </ModalContentBase>
  );
}

const useModalContext = () => React.useContext(ModalContext);

export {
  Modal,
  ModalContent,
  ModalContentBase,
  ModalOpenButton,
  useModalContext,
};
