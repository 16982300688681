import { Alert, Form, Select, Spin } from "antd";
import { AppContext } from "components/context/app/Context";
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'
import React from "react";
import { useQuery } from "react-query3";
import { Schedule, Script, ScriptParameter } from "../../../types";
import queryClient from "../../utils/queryClient";
import ComputerSelectBox from "../ComputerSelectBox";
import { Field } from "./Form";
import { CustomSchema } from "./types";

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

function setComponentByParameterType(parameter) {
  return parameter.displayType === 0
    ? "input"
    : parameter.displayType === 1
      ? "inputNumber"
      : parameter.displayType === 2
        ? "switch"
        : parameter.displayType === 3
          ? "datetimePicker"
          : parameter.displayType === 8
            ? "password"
            : "credential";
}

function setDefaultValueByParameterType(parameter, existingParameter) {
  if (existingParameter) {
    if (parameter.displayType === 3) {
      return dayjs(JSON.parse(existingParameter.objectValue));
    } else {
      return JSON.parse(existingParameter.objectValue);
    }
  }

  if (parameter?.resolvedDefaultValue) {
    switch (parameter.displayType) {
      case 1: return Number.parseInt(parameter.resolvedDefaultValue);
      case 2: return parameter.resolvedDefaultValue === "True";
      case 3:
        try {
          return dayjs(parameter.resolvedDefaultValue)
        } catch {
          return null;
        }
      default: return parameter.resolvedDefaultValue;
    }
  }
  return null;
}

const ScriptParameters = ({ form, initialValue }) => {
  let selectedScript = form.getFieldValue("script");
  const { data, isLoading } = useQuery<ScriptParameter[]>(`/script/parameter/${selectedScript}`, {
    enabled: !!selectedScript,
  });

  var { data: script } = useQuery<Script>(`/script/path/${selectedScript}`);

  const parameterSets = script?.parameterSets?.filter(x => x !== 'Default');
  const [parameterSet, setParameterSet] = React.useState(script?.defaultParameterSet || (parameterSets && parameterSets[0]));

  if (isLoading) {
    return <Spin />
  }

  if (data?.length === 0) {
    return <></>
  }

  const reserved = ['action', 'key', 'computer', 'computerName', 'name', 'timeZone']

  var parameters = data?.filter(parameter => parameterSets?.length === 0 || parameter.parameterSet === parameterSet || parameter.parameterSet === 'Default').map((parameter, idx) => {
    var existingParameter = initialValue?.find(p => p.name === parameter.name);

    if (reserved.includes(parameter.name)) {
      return <Alert type="error" message={`The parameter name '${parameter.name}' is reserved and cannot be used. Please update your script.`} />
    }

    const value = parameter.displayType === 4 ? (
      <Field
        key={parameter.id}
        name={[idx, "displayValue"]}
        label={parameter.name}
        required={parameter.required}
        initialValue={existingParameter?.objectValue || parameter.resolvedDefaultValue}
        tooltip={parameter.helpText}
        preserve={false}
        form={form}
      >
        <Select>
          {parameter.validValues?.map((validValue) => {
            return (
              <Select.Option key={validValue} value={validValue}>
                {validValue}
              </Select.Option>
            );
          })}
        </Select>
      </Field >
    ) : parameter.displayType === 6 ? (
      <Field
        key={parameter.id}
        name={[idx, "displayValue"]}
        initialValue={setDefaultValueByParameterType(parameter, existingParameter)}
        label={parameter.name}
        required={parameter.required}
        tooltip={parameter.helpText}
        preserve={false}
        form={form}
      >
        <Select mode="tags" >
          {parameter.validValues?.map((validValue) => {
            return (
              <Select.Option key={validValue} value={validValue}>
                {validValue}
              </Select.Option>
            );
          })}
        </Select>
      </Field>
    ) : (
      <Field
        key={parameter.id}
        name={[idx, "displayValue"]}
        label={parameter.name}
        required={parameter.required}
        initialValue={setDefaultValueByParameterType(parameter, existingParameter)}
        tooltip={parameter.helpText}
        component={setComponentByParameterType(parameter)}
        preserve={false}
        valuePropName={parameter.displayType === 2 ? "checked" : "value"}
        form={form}
      />
    );

    return <Form.Item noStyle>
      <Form.Item noStyle name={[idx, "name"]} initialValue={parameter.name} />
      <Form.Item noStyle name={[idx, "type"]} initialValue={parameter.type} />
      {value}</Form.Item>
  })

  return <>
    {
      parameterSets?.length > 1 && (
        <Field label="Parameter Set">
          <Select id="parameterSet" onChange={(e) => setParameterSet(e.toString())} value={parameterSet} style={{ width: '100%' }} defaultValue={selectedScript?.defaultParameterSet}>
            {parameterSets?.map(parameterSet => <Select.Option key={parameterSet} value={parameterSet}>{parameterSet}</Select.Option>)}
          </Select>
        </Field>

      )
    }
    {parameters}
  </>
}

export const scheduleSchema: CustomSchema<Schedule>[] = [
  {
    name: "type",
    label: "Type",
    component: "typeSelector",
    required: true,
    uniqe: false,
    initialValue: "simple",
  },
  {
    name: "script",
    label: "Script",
    tooltip: "Select the script to create schedule for it.",
    component: "scriptSelect",
    required: true,
    uniqe: false
  },
  {
    name: "name",
    label: "Name",
    tooltip: 'Schedule name',
    component: "input",
    required: true,
    uniqe: true
  },
  {
    name: "timeout",
    component: "inputNumber",
    label: "Time Out (Minutes)",
    initialValue: 0,
    tooltip: "The time out value in minutes. 0 means no time out.",
  },
  {
    name: "paused",
    label: "Paused",
    tooltip: "This schedule will not start jobs.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    name: "randomDelay",
    label: "Random Delay",
    tooltip: "Randomly delays the schedule start time from 0 to 60 seconds.",
    component: "switch",
    valuePropName: "checked",
    required: false,
    uniqe: false,
  },
  {
    name: "cron",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "environment",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "credential",
    hidden: true,
    style: { display: "none" }
  },
  {
    name: "computer",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "timeZoneString",
    hidden: true,
    style: { display: "none" },
  },
  {
    name: "delay",
    hidden: true,
    style: { display: "none" },
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      let selectedType = getFieldValue("type");
      let cron = getFieldValue("cron");

      return selectedType === "simple" ? (
        <Field
          component="simpleTime"
          name="simple"
          label="Time"
          tooltip="Schedules a script to run based on a simple schedule."
          preserve={false}
          initialValue={cron || "* * * * *"}
        />
      ) : selectedType === "continuous" ? (
        <Field
          component="inputNumber"
          name="delay"
          label="Delay"
          tooltip="Schedules a script to run continuously with a delay in between each run in seconds."
          preserve={false}
        //initialValue={delay || 60}
        />
      ) : selectedType === "cron" ? (
        <Field
          component="input"
          name="cron"
          label="Expression"
          tooltip="Schedules a script to run based on a CRON expression"
          preserve={false}
          hasFeedback={true}
        />
      ) : (
        <Field
          component="datetimePicker"
          name="datetime"
          label="Date"
          tooltip="Schedules a script to run one time."
          preserve={false}
        />
      );
    },
  },
  {
    uniqe: false,
    noStyle: true,
    required: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.type !== curValues.type;
    },
    children: ({ getFieldValue }) => {
      let selectedType = getFieldValue("type");

      return selectedType !== "continuous" && (
        <Field
          component="timeZone"
          name="timeZoneString"
          label="Timezone"
          tooltip="The time zone to run this schedule in."
          preserve={false}
          initialValue={"System Default"}
        />
      )
    },
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");
      let environment = getFieldValue("environment");

      let script = queryClient
        .getQueryData<Script[]>("/script")
        ?.find((script) => script.fullPath === selectedScript);
      return script?.environment === null ? (
        <Field
          component="environment"
          name="environment"
          label="Environment"
          initialValue={environment || "Default"}
          preserve={false}
        />
      ) : null;
    },
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");
      let script = queryClient
        .getQueryData<Script[]>("/script")
        ?.find((script) => script.fullPath === selectedScript);
      return script?.credential === null ? (
        <AppContext.Consumer>
          {context => {
            if (context.settings?.hideRunAs) return null;
            return <Field
              component="credential"
              name="credential"
              label="Credential"
              initialValue="Default"
              preserve={false}
            />
          }}
        </AppContext.Consumer>

      ) : null;
    },
  },
  {
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    children: ({ getFieldValue }) => {
      let selectedScript = getFieldValue("script");

      return selectedScript &&
        <Field
          key={"computer"}
          name={"computer"}
          label={"Computer"}
          required={false}
          tooltip={"The computers to run this schedule on."}
          preserve={false}
        >
          <ComputerSelectBox />
        </Field>
    }

  },
  {
    name: "parameters",
    required: false,
    uniqe: false,
    noStyle: true,
    shouldUpdate: (prevValues, curValues) => {
      return prevValues.script !== curValues.script;
    },
    render: (form, initialValue) => {
      return <Form.List name="parameters">
        {(fields) => <ScriptParameters form={form} initialValue={initialValue} />}
      </Form.List>
    }
  },
];
